import { DashboardDiscovered } from "@fscrypto/domain/dashboard";
import { Avatar, Card, TagComponent, Text } from "@fscrypto/ui";
import { Link } from "@remix-run/react";
import { $path } from "remix-routes";
import { CommentCountTag } from "~/features/comments/ui/commentCountTag";
import { DashboardLikes } from "~/features/dashboard-likes";
import { useTags } from "~/features/tags";
import { Timestamp } from "~/shared/components/timestamp";
import { useFeatureFlag } from "~/state/feature-flags";
import { tracking } from "~/utils/tracking";
import { PreviewImage } from "./preview-image";
import Projects from "./projects";
import { VisibilityIcon } from "./visibility-icon";
import { TableCell, TableRow } from "@fscrypto/flip-kit/table";

interface DashboardCardProps {
  dashboard: EnhancedDashboard;
  activeProject?: string;
  onSelectProject?: (name: string) => void;
  showUser?: boolean;
}

export interface EnhancedDashboard extends DashboardDiscovered {
  likedByMe?: boolean;
  totalLikes?: number;
}

export const DashboardCard = ({ dashboard, onSelectProject, showUser }: DashboardCardProps) => {
  const {
    title,
    description,
    profileUrl,
    profile,
    coverImage,
    id,
    latestSlug,
    totalLikes,
    tags,
    createdAt,
    likedByMe,
    profileAvatar,
    profileUsername,
    visibility,
  } = dashboard;
  const projects = tags?.filter((o) => o.type === "project") ?? [];
  const isTagsEnabled = useFeatureFlag("dashboard-tags");
  const { tags: dashboardTags } = useTags("dashboard", id, { skipFetch: typeof window === "undefined" });

  return (
    <Card asChild>
      <div className="relative">
        <Link
          to={$path("/:owner/:dashboardSlug", { owner: profile.slug, dashboardSlug: latestSlug })}
          prefetch="intent"
        >
          <VisibilityIcon visibility={visibility} />
          <PreviewImage src={coverImage?.url} alt={dashboard.title} />
          <div
            className={`relative flex ${isTagsEnabled ? "xs:h-auto sm:min-h-44" : "h-40"} w-full flex-col space-y-1 overflow-hidden p-4`}
          >
            <h3>
              <Text color="contrast" className="pointer-events-none line-clamp-2 block" weight="medium">
                {title}
              </Text>
            </h3>
            <p className="text-muted-foreground line-clamp-2 text-xs">
              <Timestamp time={createdAt} /> {!isTagsEnabled && description && <> - {description}</>}
            </p>
            {isTagsEnabled && (
              <div className="flex flex-wrap gap-y-2 h-auto max-h-16 sm:h-16 overflow-y-hidden pt-1">
                {dashboardTags?.map((tag) => (
                  <TagComponent key={tag.name} className="mr-1 hover:bg-muted-foreground/10" tag={tag} />
                ))}
              </div>
            )}
            <div className="flex-1" />
            <div className="align-end flex w-full items-center ">
              <DashboardLikes
                initialLikeCount={totalLikes ?? 0}
                initiallyLikedByMe={likedByMe ?? false}
                dashboardId={id}
                variant={"discover"}
              />
              <CommentCountTag
                resourceType="dashboard"
                resourceId={id}
                className="ml-2 mr-1"
                onClick={() => tracking("dashboard-card-click-comments", "Dashboard Beta")}
              />
              {!isTagsEnabled && <Projects projects={projects} onSelect={onSelectProject} />}
            </div>
          </div>
        </Link>
        {showUser && (
          <Link to={profileUrl ?? "/"}>
            <div className="absolute left-4 top-4 mr-4 flex max-w-[calc(100%_-_32px)] items-center truncate rounded-full bg-black bg-opacity-50 p-1 backdrop-blur">
              <Avatar src={profileAvatar!} size="sm" className="flex-shrink-0" alt={profileUsername!} />
              <span className="mx-3 line-clamp-1 truncate text-sm text-white">{profileUsername}</span>
            </div>
          </Link>
        )}
      </div>
    </Card>
  );
};

export const DashboardRow = ({ dashboard }: DashboardCardProps) => {
  const projects = dashboard.tags?.filter((o) => o.type === "project") ?? [];
  const isTagsEnabled = useFeatureFlag("dashboard-tags");
  const { tags: dashboardTags } = useTags("dashboard", dashboard.id, { skipFetch: typeof window === "undefined" });
  return (
    <TableRow key={dashboard.id} className="whitespace-nowrap">
      <TableCell className="py-5">
        <Link
          className="text-xl text-contrast"
          to={$path("/:owner/:dashboardSlug", { owner: dashboard.profile.slug, dashboardSlug: dashboard.latestSlug })}
          prefetch="intent"
        >
          {dashboard.title}
        </Link>
        {isTagsEnabled ? (
          <div className="flex flex-wrap gap-y-2 pt-1">
            {dashboardTags?.map((tag) => (
              <TagComponent key={tag.name} className="mr-1 hover:bg-muted-foreground/10" tag={tag} />
            ))}
          </div>
        ) : (
          <Projects projects={projects} />
        )}
        <p className="md:hidden">
          <Timestamp time={dashboard.createdAt} />
        </p>
      </TableCell>
      <TableCell className="hidden md:table-cell">
        <Link to={dashboard.profileUrl ?? "/"} className="text-contrast flex items-center">
          <Avatar src={dashboard.profileAvatar!} size="sm" className="flex-shrink-0" alt={dashboard.profileUsername!} />
          <span className="mx-3 line-clamp-1 truncate text-sm">{dashboard.profileUsername}</span>
        </Link>
      </TableCell>
      <TableCell className="hidden md:table-cell">
        <Timestamp time={dashboard.createdAt} />
      </TableCell>
      <TableCell className="w-0 hidden lg:table-cell">
        <div className="flex items-center space-x-4 text-contrast/50 font-medium">
          <DashboardLikes
            initialLikeCount={dashboard.totalLikes ?? 0}
            initiallyLikedByMe={dashboard.likedByMe ?? false}
            dashboardId={dashboard.id}
            variant={"discover"}
          />
          <div className="flex items-center">
            <CommentCountTag
              resourceType="dashboard"
              resourceId={dashboard.id}
              className="ml-2 mr-1"
              onClick={() => tracking("dashboard-card-click-comments", "Dashboard Beta")}
            />
          </div>
          {/* <div className="flex items-center">
              <EyeIcon className="size-4 mr-2" /> <span>5</span>
            </div> */}
        </div>
      </TableCell>
    </TableRow>
  );
};
