import { Dashboard } from "@fscrypto/domain/dashboard";
import { QueryDiscovered } from "@fscrypto/domain/query";
import { Tooltip } from "@fscrypto/ui";
import { LinkIcon, LockIcon } from "lucide-react";

export const VisibilityIcon = ({ visibility }: { visibility: Dashboard["visibility"] }) => {
  if (visibility === "public") {
    return null;
  }
  return (
    <div className="bg-gray-30 absolute right-4 top-4 z-10 rounded-full p-2">
      {visibility === "private" ? (
        <Tooltip side="top" content="Private: only members within the workspace can edit the content">
          <div>
            <LockIcon className="size-4" />
          </div>
        </Tooltip>
      ) : (
        <Tooltip side="top" content="restricted: Access is limited to users with the link">
          <div>
            <LinkIcon className="size-4" />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export const QueryVisibilityIcon = ({ visibility }: { visibility: QueryDiscovered["visibility"] }) => {
  if (visibility === "public") {
    return null;
  }
  return (
    <div className="bg-gray-15 z-10 rounded-full p-2">
      {visibility === "private" ? (
        <Tooltip side="top" content="Private: only members within the workspace can edit the content">
          <div>
            <LockIcon />
          </div>
        </Tooltip>
      ) : (
        <Tooltip side="top" content="restricted: Access is limited to users with the link">
          <div>
            <LinkIcon />
          </div>
        </Tooltip>
      )}
    </div>
  );
};
