import { Comment } from "@fscrypto/domain/comments";
import { Tag } from "@fscrypto/ui";
import { MessageCircleIcon } from "lucide-react";
import { useComments } from "../state/useComments";

export const CommentCountTag = ({
  className,
  onClick,
  resourceType,
  resourceId,
}: {
  className?: string;
  onClick?: () => void;
  resourceId: string;
  resourceType: Comment["resourceType"];
}) => {
  const { count } = useComments(resourceType, resourceId, { countOnly: true });

  return (
    <div className="flex items-center space-x-1" onClick={onClick}>
      <MessageCircleIcon className="h-4 w-4 " />
      <span>{count ? count : 0}</span>
    </div>
  );
};
